import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@components/Layout'
import { Section } from '@styles/globalComponents'
import LogoContainer from '@components/Logo'
import PageTitle from '@components/PageTitle'
import PageBody from '@components/PageBody'
import SEO from '@components/SEO'

const PageTemplate = ({ data }) => {
  const { title, metaDescription, body } = data.contentfulPage

  return (
    <Layout>
      <SEO
        title={title}
        description={
          metaDescription
          ? metaDescription.internal.content
          : body.childMarkdownRemark.excerpt
        }
      />
      <LogoContainer />
      <Section>
        <PageTitle>{title}</PageTitle>
        <PageBody body={body} />
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
      body {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 180)
        }
      }
    }
  }
`

export default PageTemplate
